<template>

  <the-modal @closeModal="closeModal">
    <template v-slot:header="">
      <h5>حذف القائمة</h5>
    </template>
    <template v-slot:body="">
      <div class="body">
       هل تريد حقاً حذف هذه القائمة للأبد؟
     </div>
   </template>
   <template v-slot:footer="">
    <div class="btn btn-hover btn-fill modal-btn btn-focus-design" @click="deleteCollection">
      حذف القائمة
    </div>
    <div class="btn btn-hover btn-fill modal-btn btn-focus-design" @click="closeModal">
      إلغاء
    </div>
  </template>
</the-modal>

</template>
<script>
  import TheModal from '@/components/modals/show.vue';
  import Resource from '@/api/resource';
  import { showLoader, hideLoader } from '@/utils/helpers';
  const collectionResource = new Resource('collections');

  export default {
    name: 'DeleteCollectionModal',
    components: {
      TheModal,
    },
    props: {
      collection: {
        type: Object,
        default: null,
        required: true,
      },
    },
    methods: {
      showLoader,
      hideLoader,
      deleteCollection() {

        this.loader = this.showLoader(this.loader);

        collectionResource.destroy(this.collection.id).then(() => {

         this.$notify({
          duration: 3500,
          title: "Inhalt entfernt!",
          text: ''
        });

       })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
          this.$router.push({name:'Profile'});
          this.closeModal();
        });
      },
      closeModal(){
        this.$emit("closeModal");
      },
    }
  };
</script>

<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';

  .modal-btn {
    width: 100%;
  }
  h5 {
    margin-left: 15px;
  }

</style>
